<template>
    <div>
        
        <div class="px-2">
            <v-container>
                <v-row no-gutters>
                    <v-col cols="7">
                        <p class="display-1 font-weight-thin mb-2">Leverancieren</p>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row> 
                <v-row>
                    <v-col class="pb-1">
                        <div>{{accountDescription}}</div>
                    </v-col>
                    <v-col cols="12" class="mb-3" >
                        <v-btn color="primary" block @click="goToNewSupplier()">
                            Voeg nieuwe leverancier toe
                        </v-btn>
                    </v-col>
                </v-row>
                
                <v-row no-gutters class="mb-5">
                    <v-col>
                        <p class="title mt-0 mb-0">Geregistreerd</p>
                    </v-col>
                    <v-col cols="12" class="ma-0 pt-0 px-0 sort-container">
                        <v-btn plain
                            class="mr-4 pa-0 black white--text text-capitalize text-start"
                            @click="sort()">
                            Bedrijfsnaam
                            <v-icon large right dark class="pr-2">{{currentSort == 'bedrijfAsc' ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" class="pt-3 pb-1 px-0" v-for="(supplier, index) in sortedArray" :key="index">
                        <v-card class="participant-card primary" @click="goToSupplierId(supplier.item1)">
                            <v-card-title
                            class="subtitle-1 cols-12 pt-2 pb-0">
                                {{ supplier.item2 }}
                            </v-card-title>
                            <v-list class="pa-0 primary">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-thin">{{ supplier.item1 }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import RouteMixin from "@/mixins/route.mixin";
import { mapState } from 'vuex';
export default {
    name: 'Leverancieren',
    components: 
    {
    },
    mixins: [RouteMixin],
    data: () => ({
        accountDescription: "Hierboven zijn de belangrijkste statistieken van de huidige periode weergegeven. Het is mogelijk om alle gegevens op deze pagina per kwartaal en per jaar te sorteren. Daarnaast zijn alle leverencieren hieronder alfabetisch gesorteerd.",
        currentSort: 'bedrijfAsc',
    }),
    computed: {
        ...mapState({
            suppliers: state => state.menuHelper.suppliers
        }),
        sortedArray() 
        {
            let sorted = this.suppliers;
		
            sorted = sorted.sort((a,b) => {
                let text1 = "",
                    text2 = "";

                switch(this.currentSort)
                {
                    case "bedrijfAsc":
                        text1 = a.item2.toLowerCase();
                        text2 = b.item2.toLowerCase();
                        break;
                    case "bedrijfDesc":
                        text1 = b.item2.toLowerCase();
                        text2 = a.item2.toLowerCase();
                        break;
                }

                if (text1 < text2) {
                    return -1
                }
                if (text1 > text2) {
                    return 1
                }
                return 0
            })
            return sorted;
        }
    },
    methods: 
    {
        sort()
        {
            this.currentSort = this.currentSort == 'bedrijfAsc' ? 'bedrijfDesc' : 'bedrijfAsc';
        },
        getCardText(item)
        {
           return `${item.name} | ${item.daysLastActive} dagen geleden actief`;
        },
        getCardSubText(item)
        {
           return `Aantal roadtrips meegereden: ${item.rideCount} (${item.kilometerRide} km)`;
        }
    }
}
</script>
<style scoped>
.header
{
    display: inline-flex;
}

.edit-icon
{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.v-input
{
    height: 25px;
}

.sort-container button.v-btn:not(.v-btn--round).v-size--default
{
    border-bottom: 1px solid #858282 !important;
    border-radius: 0px;
}

.theme--dark.v-card.participant-card,
.participant-card .theme--dark.v-list
{
    background-color: #005E39;
}

.participant-card .v-card__title
{
    margin-bottom: -10px;
}

.v-sheet.v-card.participant-card 
{
    border-radius: 10px;
}
</style>